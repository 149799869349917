import {Button, Col, Input, Popconfirm, Row, Switch, Table} from "antd";
import React, {useEffect} from "react";
import {coinListState} from "../../../../state/coins/coin-list.state";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Coin} from "../../../../types/coin";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import Search from "antd/es/input/Search";

const {Column} = Table;

export const CoinsListPage = observer(() => {
  useEffect(() => {
    coinListState.getList();
  }, []);

  const onDelete = async (record: Coin) => {
    await coinListState.delete(record.id);
    await coinListState.getList();
  };

  const renderActive = (value: boolean, record: Coin) => (
    <Switch
      checked={value}
      onChange={async v => {
        await coinListState.setAvailableForTrade(record.id, v);
        await coinListState.getList();
      }}
    />
  );

  const renderActions = (v: any, record: Coin) => (
    <div>
      <Link to={`/coins/${record.id}`}>
        <EditTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Popconfirm
        title="Удалить монету?"
        onConfirm={() => onDelete(record)}
        onCancel={() => {}}
        okText="Да"
        cancelText="Нет"
      >
        <DeleteTwoTone
          style={{fontSize: '2em', marginLeft: '8px'}}
        />
      </Popconfirm>
    </div>
  );

  const renderIcon = (v: any, record: Coin) => (
    record.icon ? <img src={record.icon} alt="" style={{height: '32px', width: '32px'}}/> : null
  );

  return (
    <>
      <Col>
        <Row justify={"space-between"}>
          <Col>
            <Input
              placeholder={'Поиск'}
              value={coinListState.filter}
              onChange={e => {
                coinListState.filter = e.target.value
              }}
            />
          </Col>
          <Col>
            <Link to={'/coins/add'}>
              <Button type="primary">
                Добавить
              </Button>
            </Link>
          </Col>
        </Row>
        <br/>
        <Table dataSource={coinListState.filteredCoins}>
          <Column title="Иконка" dataIndex="icon" key="icon" render={renderIcon}/>
          <Column title="Имя" dataIndex="name" key="name"/>
          <Column title="Код" dataIndex="code" key="code"/>
          <Column title="Сеть" dataIndex="net" key="net"/>
          <Column title="Адрес контракта" dataIndex="contractAddress" key="contractAddress"/>
          <Column title="Версия" dataIndex="version" key="version"/>
          <Column title="Доступна для покупки" dataIndex="availableForTrade" key="availableForTrade" render={renderActive}/>
          <Column title="Действия" render={renderActions}/>
        </Table>
      </Col>
    </>
  )
});

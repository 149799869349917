import {Button, Col, Popconfirm, Row, Table} from "antd";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {DeleteTwoTone, EditTwoTone} from "@ant-design/icons";
import {investmentsListState} from "../../../../state/insvestments/investments-list.state";
import {Investment} from "../../../../types/investment";

const {Column} = Table;

export const InvestmentsListPage = observer(() => {
  useEffect(() => {
    investmentsListState.getList();
  }, []);

  const onDelete = async (record: Investment) => {
    await investmentsListState.delete(record.id);
    await investmentsListState.getList();
  };

  const renderActions = (v: any, record: Investment) => (
    <div>
      <Link to={`/investments/${record.id}`}>
        <EditTwoTone
          style={{fontSize: '2em'}}
        />
      </Link>
      <Popconfirm
        title="Удалить монету?"
        onConfirm={() => onDelete(record)}
        onCancel={() => {}}
        okText="Да"
        cancelText="Нет"
      >
        <DeleteTwoTone
          style={{fontSize: '2em', marginLeft: '8px'}}
        />
      </Popconfirm>
    </div>
  );

  return (
    <>
      <Col>
        <Row>
          <Col offset={22}>
            <Link to={'/investments/add'}>
              <Button type="primary">
                Добавить
              </Button>
            </Link>
          </Col>
        </Row>
        <br/>
        <Table dataSource={investmentsListState.investments}>
          <Column title="Название" dataIndex="name" key="name"/>
          <Column title="Название монеты" dataIndex="coin" render={(text: any, item: any) => <span>{item.coin.name}</span>} key="coin"/>
          <Column title="Действия" render={renderActions} key={'actions'}/>
        </Table>
      </Col>
    </>
  )
});

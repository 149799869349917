import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Commission} from "../../types/commission";

export class CommissionState {
  commissions: Commission[] = [];
  private _id?: string;
  private _name: string = '';
  private _commission: number = 0;
  saveResponse?: Commission;
  loading: boolean = false;

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get commission(): number {
    return this._commission;
  }

  set commission(value: number) {
    this._commission = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = undefined;
    this.name = '';
    this.commission = 0;
  }

  setLoading = (v: boolean) => this.loading = v;

  prefill(data: Partial<Commission>) {
    this.id = data.id;
    this.name = data.name!;
    this.commission = data.commission!;
  }

  async getList() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/commission');
      if (!res.data.errors) {
        runInAction(() => {
          this.commissions = res.data.data.map((e: any)=>({
            ...e,
            commission: e.commission * 100
          }));
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async trySave(id: string) {
    try {
      let res: any;
      if ( +this.commission > 100 || +this.commission < 0) {
        return alert("Неверная комиссия");
      }
      this.setLoading(true);
      const data = {
        commission: +this._commission / 100,
      };
      if (id) {
        res = await ClientProvider.authorized.put(`/commission/${id}`, data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.getList();
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const commissionState: CommissionState = new CommissionState();

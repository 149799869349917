import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Story} from "../../types/story";

class StoryListState {
  stories: Story[] = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setStories(coins: Story[]) {
    this.stories = coins;
  }

  async getList() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/story');
      if (!res.data.errors) {
        runInAction(() => {
          this.stories = res.data.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async setActive(id: string, value: boolean) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/story/${id}/active`, {
        isActive: value
      });
      if (res.data.errors) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const storyListState: StoryListState = new StoryListState();

import {observer} from "mobx-react";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {UserForm} from "../../components/user-form";
import {userEditState} from "../../../../state/users/user-edit.state";
import {usersListState} from "../../../../state/users/users-list.state";

export const UserEditPage = observer(() => {
  const history = useHistory();
  const {id} = useParams() as any;

  useEffect(() => {
    const user = usersListState.users.find(e => e.id === id);
    userEditState.reset();
    if (user) {
      userEditState.prefill(user);
    } else {
      history.replace('/users');
    }
  }, [history, id]);

  return (
    <>
      <UserForm
        onSave={() => history.replace('/users')}
      />
    </>
  )
});


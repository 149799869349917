import {makeAutoObservable} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {LocalizationResponse} from "../../types/localization";

class LocalizationState {
  private _localizations?: LocalizationResponse;
  private _loading: boolean = false;

  get loading(): boolean {
    return this._loading;
  }

  set loading(value: boolean) {
    this._loading = value;
  }

  get localizations(): LocalizationResponse | undefined {
    return this._localizations;
  }

  set localizations(value: LocalizationResponse | undefined) {
    this._localizations = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async getLocalizations() {
    try {
      this.loading = true;
      const res = await ClientProvider.authorized.get('/localization');
      if (!res.data.errors) {
        this.localizations = res.data.data;
      } else {
        console.error(res);
      }
    } finally {
      this.loading = false;
    }
  }

  async updateLocalization(locale: string, data: any) {
    try {
      this.loading = true;
      const res = await ClientProvider.authorized.post(`/localization/${locale}`, data);
      if (res.data.errors) {
        console.error(res);
      }
    } finally {
      this.loading = false;
    }
  }
}

export const localizationState: LocalizationState = new LocalizationState();

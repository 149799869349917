import {Card, Typography} from "antd";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {statisticsState} from "../../../../state/statistics/statistics.state";
import {TokensStatistics} from "../../../../types/statistics";

export const StatisticsPage = observer(() => {
  useEffect(() => {
    statisticsState.tokens();
  }, []);

  const renderStatRow = (row: TokensStatistics) => (
    <Typography>
      {row.token}: {row.total}
    </Typography>
  );

  return (
    <div style={{display: "flex", justifyContent: "space-between"}}>
      <Card title="Баланс доходов по обмену ETH сети" bordered={false} style={{width: '49%', textAlign: "center"}}>
        {
          statisticsState.ethStats && statisticsState.ethStats.map(renderStatRow)
        }
      </Card>
      <Card title="Баланс доходов по обмену BSC сети" bordered={false} style={{width: '49%', textAlign: "center"}}>
        {
          statisticsState.bscStats && statisticsState.bscStats.map(renderStatRow)
        }
      </Card>
    </div>
  )
});

import React, {useEffect} from "react";
import {Button, Card, Col, Form, Input, message, Row} from 'antd';
import {observer} from "mobx-react";
import styles from './index.module.css';
import Title from "antd/lib/typography/Title";
import {forgetState} from "../../../../state/forget.state";
import {useHistory} from "react-router-dom";

const ForgetPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
      forgetState.success = false;
  }, []);
  useEffect(() => {
    if (forgetState.success) {
      forgetState.success = false;
      history.replace('/restore')
    } else {
      if (forgetState.message) {
        message.error(`Пользователь не найден`);
      }
    }
  }, [forgetState.success, forgetState.message]);

  return (
    <>
      <Col md={{span: 6, offset: 9}}>
        <Row align={'middle'} justify={'center'} style={{height: '100vh'}}>
          <Form
            className={styles.form}
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={() => forgetState.requestReset()}
          >
            <Card style={{width: 360}} bordered={true}>
              <Title level={5} style={{textAlign: 'center'}}>
                Забыли пароль?
              </Title>
              <Form.Item
                label={'Логин'}
                name={'email'}
                rules={[{required: true, message: 'Укажите почту'}]}
              >
                <Input
                  type="email"
                  value={forgetState.email}
                  onChange={e => forgetState.email = (e.target.value)}
                />
              </Form.Item>
              <Row className={styles.button}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={forgetState.loading}>
                    Отправить код на сброс пароля
                  </Button>
                </Form.Item>
              </Row>
            </Card>
          </Form>
        </Row>
      </Col>
    </>
  );
});

export default ForgetPage;

// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".layout_header__2OMlE {\n    display: flex;\n    align-items: center;\n}\n\n.layout_content__3bRQu {\n    padding: 20px;\n    overflow: scroll;\n}\n", "",{"version":3,"sources":["webpack://src/views/layout/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,aAAa;IACb,gBAAgB;AACpB","sourcesContent":[".header {\n    display: flex;\n    align-items: center;\n}\n\n.content {\n    padding: 20px;\n    overflow: scroll;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "layout_header__2OMlE",
	"content": "layout_content__3bRQu"
};
export default ___CSS_LOADER_EXPORT___;

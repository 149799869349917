import {observer} from "mobx-react";
import {Tabs} from "antd";
import {useEffect} from "react";
import {LocalizationTable} from "../../components/localization-table";
import {localizationState} from "../../../../state/localization/localization.state";

const {TabPane} = Tabs;

export const LocalizationListPage = observer(() => {
  useEffect(() => {
    localizationState.getLocalizations();
  }, []);

  return (
    <>
      <Tabs defaultActiveKey="1">
        <TabPane tab="Русский" key="ru">
          <LocalizationTable locale={'ru'}/>
        </TabPane>
        <TabPane tab="Английский" key="en">
          <LocalizationTable locale={'en'}/>
        </TabPane>
      </Tabs>
    </>
  );
});

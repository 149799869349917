import React, {useEffect} from "react";
import {Button, Card, Col, Form, Input, message, Row} from 'antd';
import {observer} from "mobx-react";
import styles from './index.module.css';
import Title from "antd/lib/typography/Title";
import {forgetState} from "../../../../state/forget.state";
import {restoreState} from "../../../../state/restore.state";
import {FieldData} from "rc-field-form/lib/interface";
import {useHistory} from "react-router-dom";

const RecoveryLoginPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    restoreState.email = forgetState.email;
    restoreState.code = '';
    restoreState.repeatPassword = '';
    restoreState.password = '';
  }, []);

  const compareReset = ()=>{
    if (restoreState.password === restoreState.repeatPassword){
      const onSuccess = () => history.replace('/login')
      restoreState.restoreReset(onSuccess);
    } else {
      message.error('Пароли не совпадают');
    }
   };


  const fields: FieldData[] = ['code', 'password', 'repeatPassword'].map( e => ({
    name: e,
    value: (restoreState as any)[e]
  }));
  return (
    <>
      <Col md={{span: 6, offset: 9}}>
        <Row align={'middle'} justify={'center'} style={{height: '100vh'}}>
          <Form
            className={styles.form}
            style={{width: '100%'}}
            fields={fields}
            layout={'vertical'}
            onFinish={() => compareReset()}
          >
            <Card style={{ width: 300 }} bordered={true}>
              <Title level={5} style={{textAlign: 'center'}}>
                Восстановить пароль
              </Title>
              <Form.Item
                  label={'Код'}
                  name={'code'}
                  rules={[{required: true, message: 'Укажите код'}]}
              >
                <Input
                    value={restoreState.code}
                    onChange={e => restoreState.code = (e.target.value)}
                />
              </Form.Item>
              <Form.Item
                  label={'Пароль'}
                  name={'password'}
                  rules={[{required: true, message: 'Введите новый пароль'}, {pattern: /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{8,15})$/, message: 'Пароль должен быть не менее 8 символов, должен содержать хотя бы одну цифру, а так же заглавную и строчную буквы.' }]}
              >
                <Input
                    type="password"
                    value={restoreState.password}
                    onChange={e => restoreState.password = (e.target.value)}
                />
              </Form.Item>
              <Form.Item
                  label={'Подтвердите пароль'}
                  name={'repeatPassword'}
                  rules={[{required: true, message: 'Подтвердите новый пароль'}]}
              >
                <Input
                    type="password"
                    value={restoreState.repeatPassword}
                    onChange={e => restoreState.repeatPassword = (e.target.value)}
                />
              </Form.Item>
              <Row className={styles.button}>
                <Form.Item>
                  <Button type="primary" htmlType="submit" loading={forgetState.loading}>
                    Восстановить пароль
                  </Button>
                </Form.Item>
              </Row>
            </Card>
          </Form>
        </Row>
      </Col>
    </>
  );
});

export default RecoveryLoginPage;

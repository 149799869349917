import {makeAutoObservable, runInAction} from "mobx";
import {storage} from "../utils/storage";
import {User} from "../types/user";
import {ClientProvider} from "../utils/apiClient/clientProvider";

class SystemState {
  authToken?: string;
  private _user?: User;

  constructor() {
    makeAutoObservable(this);
    this.setAuthToken(storage.authToken || undefined);
  }

  get user(): User | undefined {
    return this._user;
  }

  set user(value: User | undefined) {
    this._user = value;
  }

  setAuthToken = (v?: string) => {
    this.authToken = v;
    storage.authToken = v || null;
    if ( !!this.authToken && this.authToken !== 'null' && window.location.pathname !== '/login' ) {
      runInAction(async () => {
        const res = await ClientProvider.authorized.get('/user/me');
        if ( res ) {
          this._user = res.data.data;
        }
      });
    }
  }
}

export const systemState: SystemState = new SystemState();

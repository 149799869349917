// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".pages_button__2gxhK{\n    display: flex;\n    justify-content: space-between;\n}", "",{"version":3,"sources":["webpack://src/views/login/pages/index.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC","sourcesContent":[".button{\n    display: flex;\n    justify-content: space-between;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "pages_button__2gxhK"
};
export default ___CSS_LOADER_EXPORT___;

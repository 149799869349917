import {observer} from "mobx-react";
import {Button, Col, Form, Input, InputNumber, message, Row, Select, Upload, UploadProps} from "antd";
import React, {useEffect} from "react";
import {coinEditState} from "../../../../state/coins/coin-edit.state";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {UploadOutlined} from "@ant-design/icons";
import {fileUrl} from "../../../../utils/apiClient/clientProvider";
import {systemState} from "../../../../state/system.state";
import {storyEditState} from "../../../../state/story/story-edit.state";

const {Option} = Select;

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

export const CoinForm = observer(({onSave, onCancel}: Props) => {
  useEffect(() => {
    return autorun(() => {
      if (coinEditState.saveResponse) {
        coinEditState.saveResponse = undefined;
        onSave();
      }
    });
  });

  const fields: FieldData[] = ['name', 'code', 'net', 'contractAddress', 'precision', 'icon'].map(e => ({
    name: e,
    value: (coinEditState as any)[e]
  }));

  const fileProps: UploadProps = {
    accept: 'image/png',
    name: 'file',
    action: `${fileUrl}files/upload`,
    headers: {
      authorization: `Bearer ${systemState.authToken}`,
    },
    showUploadList: false,
    maxCount: 1,
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} загружен`);
        coinEditState.icon = `${fileUrl}${info.file.response.filename}`;
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} не загружен`);
      }
    },
  }

  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => coinEditState.trySave()}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (coinEditState as any)[name] = v.value;
        }}
      >
        <Form.Item
          label={'Имя токена'}
          name={'name'}
          rules={[{required: true, message: 'Укажите имя'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Код'}
          name={'code'}
          rules={[{required: true, message: 'Укажите код'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Сеть'}
          name={'net'}
          rules={[{required: true, message: 'Укажите сеть'}]}
        >
          <Select>
            <Option value="eth">ETH</Option>
            <Option value="bsc">BSC</Option>
            <Option value="tron">TRON</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label={'Адрес контракта'}
          name={'contractAddress'}
          rules={[{required: true, message: 'Укажите адрес контракта'}, {
            pattern: /^(0x[a-fA-F0-9]{40}|T[A-Za-z1-9]{33})$/,
            message: 'Неправильный адрес контракта'
          }]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Знаков после запятой'}
          name={'precision'}
          rules={[{required: true, message: 'Укажите количество знаков после запятой'}]}
        >
          <InputNumber
            style={{width: '100%'}}
            min={0}
            max={18}
          />
        </Form.Item>
        <Row>
          <Col span={24}>
            <Upload {...fileProps}>
              <Button icon={<UploadOutlined/>}>Загрузка иконки</Button>
            </Upload>
            <Form.Item
              name={'icon'}
            >
              <Input disabled={true} placeholder={'Иконка'}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={coinEditState.loading}>
            Сохранить
          </Button>
          <Button type="ghost" loading={coinEditState.loading} onClick={onCancel}>
            Отменить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});

import {AxiosRequestConfig, AxiosResponse} from "axios";
import {BaseClient} from "./baseClient";
import {storage} from "../storage";

export class AuthorizedClient extends BaseClient {
  static onSuccessResponse(res: AxiosResponse): AxiosResponse {
    if (res.status >= 200 && res.status <= 400) {
      return res;
    }

    throw new Error(res.data);
  }

  static onErrorResponse(err: any): void {
    if ( err?.response?.status === 401 ) {
      if ( window.location.pathname !== '/login' ) {
        window.location.href = '/login';
      }
    }
  }

  static onBeforeRequest(config: AxiosRequestConfig) {
    config.headers['Authorization'] = `Bearer ${storage.authToken}`;
    return config;
  }
}

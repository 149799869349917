import {observer} from "mobx-react";
import {StoryForm} from "../../components/story-form";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {storyEditState} from "../../../../state/story/story-edit.state";

export const StoryAddPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    storyEditState.reset();
  }, []);

  return (
    <>
      <StoryForm
        onSave={() => history.replace('/stories')}
      />
    </>
  )
});


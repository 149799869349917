import {observer} from "mobx-react";
import {CoinForm} from "../../components/coin-form";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {coinEditState} from "../../../../state/coins/coin-edit.state";
import {coinListState} from "../../../../state/coins/coin-list.state";

export const CoinsEditPage = observer(() => {
  const history = useHistory();
  const {id} = useParams() as any;

  useEffect(() => {
    const coin = coinListState.coins.find(e => e.id === id);
    coinEditState.reset();
    if (coin) {
      coinEditState.prefill(coin);
    } else {
      history.replace('/coins');
    }
  }, [history, id]);

  return (
    <>
      <CoinForm
        onSave={() => history.replace('/coins')}
        onCancel={() => history.replace('/coins')}
      />
    </>
  )
});


import React, {useEffect} from 'react';
import {Redirect, Route, Router, Switch} from "react-router-dom";
import LoginPage from "./views/login/pages";
import {createBrowserHistory} from "history";
import 'antd/dist/antd.css';
import {systemState} from "./state/system.state";
import {autorun} from "mobx";
import {AdminLayout} from "./views/layout";
import RecoveryLoginPage from './views/login/pages/restore';
import ForgetPage from './views/login/pages/forget';

const history = createBrowserHistory();

function App() {
  useEffect(() => {
    return autorun(() => {
      if (!systemState.authToken) {
        history.replace('/login');
      }
    });
  });

  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route path="/login">
            <LoginPage/>
          </Route>
          <Route path="/forget">
            <ForgetPage/>
          </Route>
          <Route path="/restore">
            <RecoveryLoginPage/>
          </Route>
          <Route path={'/'} exact={true}>
            <Redirect
              to={'/users'}
            />
          </Route>
          <Route path={'/'}>
            <AdminLayout/>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

import {observer} from "mobx-react";
import {Button, Form, Input, Select} from "antd";
import React, {useEffect} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {userEditState} from "../../../../state/users/user-edit.state";
import {coinEditState} from "../../../../state/coins/coin-edit.state";

const {Option} = Select;

interface Props {
  onSave: () => void;
}

function handleChange(value: string) {
  userEditState.role = value;
  console.log(`selected ${value}`);
}

export const UserForm = observer(({onSave}: Props) => {
  useEffect(() => {
    return autorun(() => {
      if (userEditState.saveResponse) {
        userEditState.saveResponse = undefined;
        onSave();
      }
    });
  });

  const fields: FieldData[] = ['surname', 'name', 'patronymic', 'email', 'role', 'password'].map(e => ({
    name: e,
    value: (userEditState as any)[e]
  }));

  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => userEditState.trySave()}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (userEditState as any)[name] = v.value;
        }}
      >
        <Form.Item
          label={'Фамилия'}
          name={'surname'}
          rules={[{required: true, message: 'Укажите фамилию'}]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={'Имя'}
          name={'name'}
          rules={[{required: true, message: 'Укажите имя'}]}
        >
          <Input/>
        </Form.Item>

        <Form.Item
          label={'Отчество'}
          name={'patronymic'}
          rules={[{required: false, message: 'Укажите отчество'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Email'}
          name={'email'}
          rules={[{required: true, message: 'Укажите email'}]}
        >
          <Input autoComplete={'off'}/>
        </Form.Item>
        <Form.Item
            required={true}
            label={'Роли'}
            name={'role'}
            rules={[{type: "string", message: 'Укажите роли'}]}
        >
          <Select
              allowClear
              style={{ width: '100%' }}
              placeholder="Выберите роли"
              value={userEditState.role}
              onChange={handleChange}
          >
            <Option value={"ADMIN"} key={"ADMIN"}>Администратор</Option>
            <Option value={"MARKETER"} key={"MARKETER"}>Маркетолог</Option>
            <Option value={"TRANSLATOR"} key={"TRANSLATOR"}>Переводчик</Option>
          </Select>
          <br />
        </Form.Item>
        <Form.Item
          label={'Пароль'}
          name={'password'}
          rules={[{
            required: true,
            message: 'Укажите пароль'
          }, {
            pattern: /^(?!^[0-9]*$)(?!^[a-zA-Z]*$)^([a-zA-Z0-9]{6,15})$/,
            message: 'Пароль должен быть не менее 6 символов, должен содержать хотя бы одну цифру, а так же заглавную и строчную буквы.'
          }]}
        >
          <Input.Password min={6}/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={userEditState.loading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});

import {observer} from "mobx-react";
import {UserForm} from "../../components/user-form";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {userEditState} from "../../../../state/users/user-edit.state";

export const UserAddPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    userEditState.reset();
  }, []);

  return (
    <>
      <UserForm
        onSave={() => history.replace('/users')}
      />
    </>
  )
});


import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {TokensStatistics} from "../../types/statistics";

export class StatisticsState {
  private _ethStats: TokensStatistics[] | undefined;
  private _bscStats: TokensStatistics[] | undefined;

  get bscStats(): TokensStatistics[] | undefined {
    return this._bscStats;
  }

  set bscStats(value: TokensStatistics[] | undefined) {
    this._bscStats = value;
  }

  get ethStats(): TokensStatistics[] | undefined {
    return this._ethStats;
  }

  set ethStats(value: TokensStatistics[] | undefined) {
    this._ethStats = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  async tokens() {
    ClientProvider.authorized.get('/statistics/eth')
      .then(r => {
        runInAction(() => {
          this._ethStats = r.data.data;
        });
      });
    ClientProvider.authorized.get('/statistics/bsc')
      .then(r => {
        runInAction(() => {
          this._bscStats = r.data.data;
        });
      });
  }
}

export const statisticsState: StatisticsState = new StatisticsState();

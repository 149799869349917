import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Coin} from "../../types/coin";

class CoinListState {
  coins: Coin[] = [];
  loading: boolean = false;
  filter: string = '';

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setCoins(coins: Coin[]) {
    this.coins = coins;
  }

  async getList() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/coin');
      if (!res.data.errors) {
        runInAction(() => {
          this.coins = res.data.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async setAvailableForTrade(id: string, value: boolean) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.put(`/coin/${id}/active`, {
        availableForTrade: value
      });
      if (res.data.errors) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async delete(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.delete(`/coin/${id}`);
      if (res.data.errors) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  get filteredCoins() {
    const f = this.filter.toLowerCase();
    return this.coins.filter(
        e => e.code.toLowerCase().startsWith(f) || e.name.toLowerCase().startsWith(f) || e.contractAddress.toLowerCase().startsWith(f)
    );
  }
}

export const coinListState: CoinListState = new CoinListState();

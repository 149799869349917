import {Button, Col, Row, Switch, Table} from "antd";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {Coin} from "../../../../types/coin";
import {EditTwoTone} from "@ant-design/icons";
import {storyListState} from "../../../../state/story/story-list.state";
import {Story} from "../../../../types/story";

const {Column} = Table;

export const StoryListPage = observer(() => {
  useEffect(() => {
    storyListState.getList();
  }, []);

  const renderActive = (value: boolean, record: Coin) => (
    <Switch
      checked={value}
      onChange={async v => {
        await storyListState.setActive(record.id, v);
        await storyListState.getList();
      }}
    />
  );

  const renderActions = (v: any, record: Story) => (
    <Link to={`/stories/${record.id}`}>
      <EditTwoTone
        style={{fontSize: '2em'}}
      />
    </Link>
  );

  const renderPreviewAsset = (v: any, record: Story) => (
    record.previewAsset ? <img src={record.previewAsset} alt="" style={{height: '32px', width: '32px'}}/> : null
  );

  return (
    <>
      <Col>
        <Row>
          <Col offset={22}>
            <Link to={'/stories/add'}>
              <Button type="primary">
                Добавить
              </Button>
            </Link>
          </Col>
        </Row>
        <br/>
        <Table dataSource={storyListState.stories}>
          <Column title="Id" dataIndex="id" key="id" width={370}/>
          <Column title="Превью" dataIndex="previewAsset" key="previewAsset" render={renderPreviewAsset}/>
          <Column title="Заголовок" dataIndex="title" key="title"/>
          <Column title="Активна" dataIndex="isActive" key="isActive" render={renderActive}/>
          <Column title="Действия" render={renderActions}/>
        </Table>
      </Col>
    </>
  )
});

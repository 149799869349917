import {observer} from "mobx-react";
import {AutoComplete, Button, Col, Form, Input, InputNumber, message, Row, Select, Upload, UploadProps} from "antd";
import React, {useEffect, useState} from "react";
import {coinEditState} from "../../../../state/coins/coin-edit.state";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {UploadOutlined} from "@ant-design/icons";
import {fileUrl} from "../../../../utils/apiClient/clientProvider";
import {systemState} from "../../../../state/system.state";
import {storyEditState} from "../../../../state/story/story-edit.state";
import {investmentEditState} from "../../../../state/insvestments/investment-edit.state";
import {coinListState} from "../../../../state/coins/coin-list.state";

const {Option} = Select;

interface Props {
  onSave: () => void;
  onCancel: () => void;
}

export const InvestmentForm = observer(({onSave, onCancel}: Props) => {
  const [coins, setCoins] = useState<{ label: string; value: string }[]>([]);
  useEffect(() => {
    return autorun(() => {
      if (investmentEditState.saveResponse) {
        investmentEditState.saveResponse = undefined;
        onSave();
      }
    });
  });
  useEffect(() => {
    coinListState.getList()
        .then(() => {
          setCoins(
              coinListState.coins.map(e => ({
                label: e.name,
                value: e.id
              }))
          )
        });
  }, []);

  const fileProps: UploadProps = {
    accept: 'image/png',
    name: 'file',
    action: `${fileUrl}files/upload`,
    headers: {
      authorization: `Bearer ${systemState.authToken}`,
    },
    showUploadList: false,
    maxCount: 1,
    onChange(info: any) {
      if (info.file.status !== 'uploading') {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} загружен`);
        investmentEditState.icon = `${fileUrl}${info.file.response.filename}`;
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} не загружен`);
      }
    },
  }

  const fields: FieldData[] = ['type', 'name', 'description', 'url', 'coinId', 'daysForIncome', 'income', 'icon'].map(e => ({
    name: e,
    value: (investmentEditState as any)[e]
  }));

  return (
      <>
        <Form
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={() => investmentEditState.trySave()}
            fields={fields}
            onFieldsChange={(f) => {
              const [v] = f;
              const name = (v.name as any[])[0];
              (investmentEditState as any)[name] = v.value;
            }}
        >
          <Form.Item
              label={'Название ресурса'}
              name={'name'}
              rules={[{required: true, message: 'Укажите имя'}]}
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Описание'}
              name={'description'}
          >
            <Input/>
          </Form.Item>
          <Row>
            <Col span={24}>
              <Upload {...fileProps}>
                <Button icon={<UploadOutlined/>}>Загрузка иконки обменника</Button>
              </Upload>
              <Form.Item
                  name={'icon'}
              >
                <Input disabled={true} placeholder={'Иконка'}/>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item
              label={'URL'}
              name={'url'}
          >
            <Input/>
          </Form.Item>
          <Form.Item
              label={'Тип'}
              name={'type'}
              rules={[{required: true, message: 'Укажите тип инвестирования'}]}
          >
            <Select>
              <Option value="INVESTMENT">Стейкинг</Option>
              <Option value="LIQUIDITY">Ликвидность</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label={'Монета'}
            name={'coinId'}
            rules={[{required: true, message: 'Укажите монету'}]}
          >
            <Select
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => (option!.children as unknown as string).includes(input)}
            >
              {
                coins.map( e=> (
                    <Option
                      key={e.value}
                      value={e.value}
                    >
                      {e.label}
                    </Option>
                ))
              }
            </Select>
          </Form.Item>
          <Form.Item
              label={'Ожидаемая доходность (дней)'}
              name={'daysForIncome'}
              rules={[{required: true, message: 'Укажите значение'}]}
          >
            <InputNumber
                style={{width: '100%'}}
                min={0}
            />
          </Form.Item>
          <Form.Item
              label={'Ожидаемая доходность (%)'}
              name={'income'}
              rules={[{required: true, message: 'Укажите значение'}]}
          >
            <InputNumber
                style={{width: '100%'}}
            />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={coinEditState.loading}>
              Сохранить
            </Button>
            <Button type="ghost" loading={coinEditState.loading} onClick={onCancel}>
              Отменить
            </Button>
          </Form.Item>
        </Form>
      </>
  );
});

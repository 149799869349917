import {observer} from "mobx-react";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {investmentEditState} from "../../../../state/insvestments/investment-edit.state";
import {InvestmentForm} from "../../components/investment-form";

export const InvestmentsAddPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    investmentEditState.reset();
  }, []);

  return (
    <>
      <InvestmentForm
        onSave={() => history.replace('/investments')}
        onCancel={() => history.replace('/investments')}
      />
    </>
  )
});


import React, {useEffect} from "react";
import {Button, Col, Form, Input, Row} from 'antd';
import {observer} from "mobx-react";
import {loginState} from "../../../state/login.state";
import {autorun} from "mobx";
import {systemState} from "../../../state/system.state";
import {Link, useHistory} from "react-router-dom";
import styles from './index.module.css';

const LoginPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    return autorun(() => {
      if (loginState.loginResponse) {
        systemState.setAuthToken(loginState.loginResponse.accessToken as string);
        window.location.href = '/';
      }
    });
  }, [history]);

  return (
    <>
      <Col md={{span: 6, offset: 9}}>
        <Row align={'middle'} justify={'center'} style={{height: '100vh'}}>
          <Form
            style={{width: '100%'}}
            layout={'vertical'}
            onFinish={() => loginState.tryLogin()}
          >
            <Form.Item
              label={'E-Mail адрес'}
              name={'email'}
              rules={[{required: true, message: 'Укажите почту'}]}
            >
              <Input
                type="email"
                value={loginState.email}
                onChange={e => loginState.setEmail(e.target.value)}
              />
            </Form.Item>
            <Form.Item
              label={'Пароль'}
              name={'password'}
              rules={[{required: true, message: 'Укажите пароль'}]}
            >
              <Input
                type="password"
                value={loginState.password}
                onChange={e => loginState.setPassword(e.target.value)}
              />
            </Form.Item>
            <Row className={styles.button}>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Вход
                </Button>
              </Form.Item>
              <Form.Item>
                <Link to={'/forget'}>
                <Button type="primary" >
                  Забыли пароль?
                </Button>
                </Link>
              </Form.Item>
            </Row>
          </Form>
        </Row>
      </Col>
    </>
  );
});

export default LoginPage;

import {makeAutoObservable} from "mobx";
import {ClientProvider} from "../utils/apiClient/clientProvider";

class ForgetState {
    private _email: string = '';
    private _loading: boolean = false;
    private _success: boolean = false;
    private _message: boolean = false;

    get success(): boolean {
        return this._success;
    }

    set success(value: boolean) {
        this._success = value;
    }

    get message(): boolean {
        return this._message;
    }

    set message(value: boolean) {
        this._message = value;
    }

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async requestReset() {
        try {
            this._loading = true;
            const res = await ClientProvider.authorized.post(`auth/forget`, {
                login: this.email
            });
            if (res) {
                this._loading = false;
                if (!res.data.errors){
                    this._message = false;
                    this._success = true;
                } else {
                    this._message = true;
                }
            }
        } catch (e) {
            console.log(e);
            this._loading = false;
        }
    }
}

export const forgetState: ForgetState = new ForgetState();

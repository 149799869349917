class StorageService {
  set authToken(v: string | null) {
    localStorage.setItem('authToken', v as unknown as string)
  }

  get authToken(): string | null {
    return localStorage.getItem('authToken')
  }
}

export const storage = new StorageService();

import {makeAutoObservable, runInAction} from "mobx";
import {Coin} from "../../types/coin";
import {ClientProvider} from "../../utils/apiClient/clientProvider";

export class CoinEditState {
  private _id?: string;
  private _name: string = '';
  private _net: string = 'eth';
  private _precision: number = 18;
  private _code: string = '';
  private _contractAddress: string = '';
  private _icon?: string;
  loading: boolean = false;
  saveResponse?: Coin;

  setLoading = (v: boolean) => this.loading = v;

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get contractAddress(): string {
    return this._contractAddress;
  }

  set contractAddress(value: string) {
    this._contractAddress = value;
  }

  get code(): string {
    return this._code;
  }

  set code(value: string) {
    this._code = value;
  }

  get precision(): number {
    return this._precision;
  }

  set precision(value: number) {
    this._precision = value;
  }

  get net(): string {
    return this._net;
  }

  set net(value: string) {
    this._net = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get icon(): string | undefined {
    return this._icon;
  }

  set icon(value: string | undefined) {
    this._icon = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = undefined;
    this.name = '';
    this.code = '';
    this.net = 'eth';
    this.contractAddress = '';
    this.precision = 18;
    this.icon = undefined;
    this.saveResponse = undefined;
  }

  prefill(data: Partial<Coin>) {
    this.id = data.id;
    this.name = data.name!;
    this.code = data.code!;
    this.net = data.net!;
    this.contractAddress = data.contractAddress!;
    this.precision = data.precision!;
    this._icon = data.icon || undefined;
  }

  async trySave() {
    try {
      let res: any;
      this.setLoading(true);
      const data = {
        name: this._name,
        code: this._code,
        contractAddress: this._contractAddress,
        precision: +this._precision,
        net: this._net,
        icon: this._icon
      };
      if (this.id) {
        res = await ClientProvider.authorized.put(`/coin/${this.id}`, data);
      } else {
        res = await ClientProvider.authorized.post('/coin', data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}


export const coinEditState: CoinEditState = new CoinEditState();

import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../utils/apiClient/clientProvider";

class LoginState {
  email: string = '';
  password: string = '';
  loginResponse?: LoginResponseDto;
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setEmail = (v: string) => this.email = v;

  setPassword = (v: string) => this.password = v;

  async tryLogin() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.default.post<LoginResponseDto>('/auth/login', {
        login: this.email,
        password: this.password
      });
      runInAction(() => {
        this.loginResponse = res.data;
      })
    } catch (e) {
      alert('Нет доступа');
    } finally {
      this.setLoading(false);
    }
  }
}

interface LoginResponseDto {
  accessToken: string;
}

export const loginState: LoginState = new LoginState();

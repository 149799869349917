import {makeAutoObservable, runInAction} from "mobx";
import {Story} from "../../types/story";
import {ClientProvider} from "../../utils/apiClient/clientProvider";

export class StoryEditState {
  private _id?: string;
  private _title?: string;
  private _subtitle: string = '';
  private _asset: string = '';
  private _previewAsset: string = '';
  private _link?: string;
  private _sort: number = 0;
  private _isActive: boolean = true;
  private _createdAt: Date = new Date();
  loading: boolean = false;
  saveResponse?: Story;

  setLoading = (v: boolean) => this.loading = v;

  get createdAt(): Date {
    return this._createdAt;
  }

  set createdAt(value: Date) {
    this._createdAt = value;
  }

  get isActive(): boolean {
    return this._isActive;
  }

  set isActive(value: boolean) {
    this._isActive = value;
  }

  get sort(): number {
    return this._sort;
  }

  set sort(value: number) {
    this._sort = value;
  }

  get link(): string | undefined {
    return this._link;
  }

  set link(value: string | undefined) {
    this._link = value;
  }

  get previewAsset(): string {
    return this._previewAsset;
  }

  set previewAsset(value: string) {
    this._previewAsset = value;
  }

  get asset(): string {
    return this._asset;
  }

  set asset(value: string) {
    this._asset = value;
  }

  get subtitle(): string {
    return this._subtitle;
  }

  set subtitle(value: string) {
    this._subtitle = value;
  }

  get title(): string | undefined {
    return this._title;
  }

  set title(value: string | undefined) {
    this._title = value;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = undefined;
    this.asset = '';
    this.previewAsset = '';
    this.sort = 0;
    this.title = ''
    this.subtitle = '';
    this.link = '';
    this.isActive = true;
    this.saveResponse = undefined;
  }

  prefill(data: Partial<Story>) {
    this.id = data.id;
    this.asset = data.asset!;
    this.previewAsset = data.previewAsset!;
    this.sort = data.sort!;
    this.title = data.title;
    this.subtitle = data.subtitle!;
    this.link = data.link;
    this.isActive = data.isActive!;
  }

  async trySave() {
    try {
      let res: any;
      this.setLoading(true);
      const data = {
        asset: this._asset,
        previewAsset: this.previewAsset,
        sort: this._sort,
        title: this._title,
        subtitle: this._subtitle,
        link: this._link,
        isActive: this._isActive
      };
      if (this.id) {
        res = await ClientProvider.authorized.put(`/story/${this.id}`, data);
      } else {
        res = await ClientProvider.authorized.post('/story', data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}


export const storyEditState: StoryEditState = new StoryEditState();

import {observer} from "mobx-react";
import {CoinForm} from "../../components/coin-form";
import {useHistory} from "react-router-dom";
import {useEffect} from "react";
import {coinEditState} from "../../../../state/coins/coin-edit.state";

export const CoinsAddPage = observer(() => {
  const history = useHistory();
  useEffect(() => {
    coinEditState.reset();
  }, []);

  return (
    <>
      <CoinForm
        onSave={() => history.replace('/coins')}
        onCancel={() => history.replace('/coins')}
      />
    </>
  )
});


import {makeAutoObservable, runInAction} from "mobx";
import {Coin} from "../../types/coin";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Investment} from "../../types/investment";

export class InvestmentEditState {
  private _id?: string;
  private _type: string = 'INVESTMENT';
  private _name: string = '';
  private _description = '';
  private _url = '';
  private _icon = '';
  private _coinId?: string;
  private _daysForIncome: string = '';
  private _income: string = '';
  loading: boolean = false;
  saveResponse?: Investment;

  get income(): string {
    return this._income;
  }

  set income(value: string) {
    this._income = value;
  }

  get daysForIncome(): string {
    return this._daysForIncome;
  }

  set daysForIncome(value: string) {
    this._daysForIncome = value;
  }

  get coinId(): string | undefined {
    return this._coinId;
  }

  set coinId(value: string | undefined) {
    this._coinId = value;
  }

  get url(): string {
    return this._url;
  }

  set url(value: string) {
    this._url = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get icon(): string {
    return this._icon;
  }

  set icon(value: string) {
    this._icon = value;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get type(): string {
    return this._type;
  }

  set type(value: string) {
    this._type = value;
  }

  setLoading = (v: boolean) => this.loading = v;

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = undefined;
    this.type = 'INVESTMENT';
    this.coinId = undefined;
    this.name = '';
    this.description = '';
    this.daysForIncome = '';
    this.income = '';
    this.url = '';
    this.icon = '';
    this.saveResponse = undefined;
  }

  prefill(data: Partial<Investment>) {
    this.id = data.id;
    this.coinId = data.coin?.id;
    this.type = data.type!;
    this.name = data.name!;
    this.description = data.description!;
    this.daysForIncome = data.daysForIncome!.toString();
    this.income = data.income!.toString();
    this.url = data.url!;
    this.icon = data.icon!;
  }

  async trySave() {
    try {
      let res: any;
      this.setLoading(true);
      const data = {
        type: this._type,
        name: this._name,
        description: this._description,
        url: this._url,
        coinId: this._coinId,
        daysForIncome: +this._daysForIncome,
        income: +this._income,
        icon: this._icon,
      };
      if (this.id) {
        res = await ClientProvider.authorized.put(`/investment/${this.id}`, data);
      } else {
        res = await ClientProvider.authorized.post('/investment', data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}


export const investmentEditState: InvestmentEditState = new InvestmentEditState();

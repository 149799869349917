import {observer} from "mobx-react";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {investmentEditState} from "../../../../state/insvestments/investment-edit.state";
import {investmentsListState} from "../../../../state/insvestments/investments-list.state";
import {InvestmentForm} from "../../components/investment-form";

export const InvestmentsEditPage = observer(() => {
  const history = useHistory();
  const {id} = useParams() as any;

  useEffect(() => {
    const coin = investmentsListState.investments.find(e => e.id === id);
    investmentEditState.reset();
    if (coin) {
      investmentEditState.prefill(coin);
    } else {
      history.replace('/investments');
    }
  }, [history, id]);

  return (
    <>
      <InvestmentForm
        onSave={() => history.replace('/investments')}
        onCancel={() => history.replace('/investments')}
      />
    </>
  )
});


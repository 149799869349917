import {Layout, Typography, Menu, Button} from 'antd';
import {Link, Route, Switch, useHistory} from 'react-router-dom';
import styles from './index.module.css';
import {CoinsEditPage} from "../coins/pages/edit";
import {CoinsAddPage} from "../coins/pages/add";
import {LocalizationListPage} from "../localization/pages/list";
import {CommissionPage} from "../commission/pages/list";
import {StatisticsPage} from '../statistics/pages/list';
import {UsersListPage} from "../users/pages/list";
import {UserAddPage} from "../users/pages/add";
import {UserEditPage} from '../users/pages/edit';
import React from "react";
import {CoinsListPage} from "../coins/pages/list";
import {StoryListPage} from "../stories/pages/list";
import {StoryEditPage} from "../stories/pages/edit";
import {StoryAddPage} from "../stories/pages/add";
import {systemState} from "../../state/system.state";
import {observer} from "mobx-react";
import {InvestmentsListPage} from "../investments/pages/list";
import {InvestmentsAddPage} from "../investments/pages/add";
import {InvestmentsEditPage} from "../investments/pages/edit";

const {Header, Sider, Content} = Layout;

export const AdminLayout = observer(() => {
  const history = useHistory();
  const getDefaultSelectedKeys = () => {
    const p = history.location.pathname;
    if (p.includes('coins')) {
      return ['coins'];
    } else if (p.includes('localization')) {
      return ['localization'];
    }
  };

  const onExit = () => {
    localStorage.clear();
    window.location.href = '/login';
  }

  const isAdmin = systemState.user?.role === "ADMIN";

  return (
    <Layout style={{height: '100vh'}}>
      <Sider>
        <div style={{width: 200, marginTop: '60px'}}>
          <Menu
            defaultSelectedKeys={getDefaultSelectedKeys()}
            mode="inline"
            theme="dark"
          >
            {
              isAdmin && (
                <Menu.Item key="users">
                  <Link to={'/users'}>
                    Администраторы
                  </Link>
                </Menu.Item>
              )
            }
            {(isAdmin || systemState?.user?.role === "MARKETER") && (
              <Menu.Item key="stories">
                <Link to={'/stories'}>
                  Стори
                </Link>
              </Menu.Item>
            )}
            {
              isAdmin && (
                <Menu.Item key="coins">
                  <Link to={'/coins'}>
                    Монеты
                  </Link>
                </Menu.Item>
              )
            }
            {
              isAdmin && (
                  <Menu.Item key="investments">
                    <Link to={'/investments'}>
                      Инвестиции
                    </Link>
                  </Menu.Item>
              )
            }
            {
              (isAdmin || systemState.user?.role === "TRANSLATOR") && (
                <Menu.Item key="localization">
                  <Link to={'/localization'}>
                    Локализация
                  </Link>
                </Menu.Item>
              )
            }
            {
              isAdmin && (
                <>
                  <Menu.Item key="commission">
                    <Link to={'/commission'}>
                      Комиссии
                    </Link>
                  </Menu.Item>
                  <Menu.Item key="statistics">
                    <Link to={'/statistics'}>
                      Статистика
                    </Link>
                  </Menu.Item>
                </>
              )
            }
          </Menu>
        </div>
      </Sider>
      <Layout>
        <Header className={styles.header} style={{display: "flex", justifyContent: "space-between"}}>
          <Typography.Title style={{color: 'white'}} level={4}>
            Payswap
          </Typography.Title>
          <Button type="primary" onClick={onExit}>
            Выйти
          </Button>
        </Header>
        <Content className={styles.content}>
          <Switch>
            {isAdmin && (
              <Route path={'/users'} exact={true}>
                <UsersListPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/users/add'} exact={true}>
                <UserAddPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/users/:id'}>
                <UserEditPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/coins'} exact={true}>
                <CoinsListPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/coins/add'} exact={true}>
                <CoinsAddPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/coins/:id'}>
                <CoinsEditPage/>
              </Route>
            )}
            {isAdmin && (
                <Route path={'/investments'} exact={true}>
                  <InvestmentsListPage/>
                </Route>
            )}
            {isAdmin && (
                <Route path={'/investments/add'} exact={true}>
                  <InvestmentsAddPage/>
                </Route>
            )}
            {isAdmin && (
                <Route path={'/investments/:id'}>
                  <InvestmentsEditPage/>
                </Route>
            )}
            {isAdmin && (
              <Route path={'/commission'} exact={true}>
                <CommissionPage/>
              </Route>
            )}
            {isAdmin && (
              <Route path={'/statistics'} exact={true}>
                <StatisticsPage/>
              </Route>
            )}
            {(isAdmin || systemState.user?.role === "TRANSLATOR") && (
              <Route path={'/localization'} exact={true}>
                <LocalizationListPage/>
              </Route>
            )}
            {(isAdmin || systemState.user?.role === "MARKETER") && (
              <Route path={'/stories'} exact={true}>
                <StoryListPage/>
              </Route>
            )}
            {(isAdmin || systemState.user?.role === "MARKETER") && (
              <Route path={'/stories/add'} exact={true}>
                <StoryAddPage/>
              </Route>
            )}
            {(isAdmin || systemState.user?.role === "MARKETER") && (
              <Route path={'/stories/:id'} exact={true}>
                <StoryEditPage/>
              </Route>
            )}
          </Switch>
        </Content>
        {/*<Footer>Footer</Footer>*/}
      </Layout>
    </Layout>
  );
});

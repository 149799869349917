import {InputNumber, Table} from "antd";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {EditTwoTone, SaveTwoTone} from "@ant-design/icons";
import {commissionState} from "../../../../state/commission/commission.state";
import {Commission} from "../../../../types/commission";

const {Column} = Table;

export const CommissionPage = observer(() => {
  const [editedRow, setEditedRow] = useState();
  const [editedField, setEditedField] = useState('');
  const [editedValue, setEditedValue] = useState('');

  useEffect(() => {
    commissionState.getList();
  }, []);

  const onSave = async (record: any) => {
    setEditedRow(undefined);
    const data: any = {};
    commissionState.trySave(record.id)
  }
  const renderActions = (v: any, record: any) => {
    console.log(record.id === editedRow, record.id, editedRow);
    const components: JSX.Element[] = [];
    if (record.id === editedRow) {
      components.push(
        <SaveTwoTone
          style={{fontSize: '2em'}}
          onClick={() => onSave(record)}
        />
      )
    } else {
      components.push(
        <EditTwoTone
          onClick={() => {
            setEditedRow(record.id);
            //setEditedField(record.field);
            setEditedValue('');
          }}
          style={{fontSize: '2em'}}
        />
      );
    }

    return (
      <div style={{display: "flex", justifyContent: "space-between"}}>
        {components}
      </div>
    );
  };
  const renderField = (v: any, record: Commission) => {
    if (record.id !== editedRow) {
      return <span style={{fontWeight: 700}}>{record.commission}</span>
    }

    return (
      <InputNumber
        defaultValue={record.commission}
        min={0}
        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        style={{width: '80%'}}
        onPressEnter={(e) => {
          console.log("RRR", (e.target as any).value);
          //commissionState.commission = (e.target as any).value;
          //setEditedField(e.target.value)
        }}
        onChange={(e) => {
          commissionState.commission = e as any;
          //setEditedField(e.target.value)
        }}
        bordered={false}
      />
    );
  }

  return (
    <>
      <Table dataSource={commissionState.commissions} key='id'>
        <Column title="Имя" dataIndex="name" key="name"/>
        <Column title="Комиссия %" dataIndex="commission" key='id' render={renderField}/>
        <Column title="Действия" render={renderActions}/>
      </Table>
    </>
  )
});

import {observer} from "mobx-react";
import {Button, Col, Form, Input, InputNumber, message, Row, Upload, UploadProps} from "antd";
import React, {useEffect} from "react";
import {autorun} from "mobx";
import {FieldData} from "rc-field-form/lib/interface";
import {UploadOutlined} from "@ant-design/icons";
import {fileUrl} from "../../../../utils/apiClient/clientProvider";
import {systemState} from "../../../../state/system.state";
import {storyEditState} from "../../../../state/story/story-edit.state";

interface Props {
  onSave: () => void;
}

export const StoryForm = observer(({onSave}: Props) => {
  useEffect(() => {
    return autorun(() => {
      if (storyEditState.saveResponse) {
        storyEditState.saveResponse = undefined;
        onSave();
      }
    });
  });

  const fields: FieldData[] = ['title', 'subtitle', 'asset', 'previewAsset', 'link', 'sort'].map(e => ({
    name: e,
    value: (storyEditState as any)[e]
  }));

  const fileProps: UploadProps = {
    accept: 'image/png, image/jpeg',
    name: 'file',
    action: `${fileUrl}files/upload`,
    headers: {
      authorization: `Bearer ${systemState.authToken}`,
    },
    showUploadList: false,
    maxCount: 1,
  }

  const onFileChange = (info: any, field: keyof typeof storyEditState) => {
    if (info.file.status !== 'uploading') {
      console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      message.success(`${info.file.name} загружен`);
      const resp = `${fileUrl}${info.file.response.filename}`;
      if (field === "asset") {
        storyEditState.asset = resp;
      } else if (field === "previewAsset") {
        storyEditState.previewAsset = resp;
      }
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} не загружен`);
    }
  };

  return (
    <>
      <Form
        style={{width: '100%'}}
        layout={'vertical'}
        onFinish={() => storyEditState.trySave()}
        fields={fields}
        onFieldsChange={(f) => {
          const [v] = f;
          const name = (v.name as any[])[0];
          (storyEditState as any)[name] = v.value;
        }}
      >
        <Form.Item
          label={'Заголовок'}
          name={'title'}
          rules={[{required: false}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Подзаголовок'}
          name={'subtitle'}
          rules={[{required: true, message: 'Укажите подзаголовок'}]}
        >
          <Input/>
        </Form.Item>
        <Form.Item
          label={'Порядок'}
          name={'sort'}
          rules={[{required: true, message: 'Укажите порядок'}]}
        >
          <InputNumber
            style={{width: '100%'}}
            min={0}
            max={18}
          />
        </Form.Item>
        <Form.Item
          label={'Ссылка'}
          name={'link'}
          rules={[{required: true, message: 'Укажите ссылку'}]}
        >
          <Input/>
        </Form.Item>
        <Row>
          <Col span={24}>
            <Upload {...fileProps} onChange={(i) => onFileChange(i, 'asset')}>
              <Button icon={<UploadOutlined/>}>Загрузка основной картинки</Button>
            </Upload>
            <Form.Item
              name={'asset'}
            >
              <Input disabled={true} placeholder={'Основная картинка'}/>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Upload {...fileProps} onChange={(i) => onFileChange(i, 'previewAsset')}>
              <Button icon={<UploadOutlined/>}>Загрузка картинки предпросмотра</Button>
            </Upload>
            <Form.Item
              name={'previewAsset'}
            >
              <Input disabled={true} placeholder={'Картинка предпросмотра'}/>
            </Form.Item>
          </Col>
        </Row>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={storyEditState.loading}>
            Сохранить
          </Button>
        </Form.Item>
      </Form>
    </>
  );
});

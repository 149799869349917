import {Button, Col, Row, Switch, Table} from "antd";
import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Link} from "react-router-dom";
import {EditTwoTone} from "@ant-design/icons";
import {usersListState} from "../../../../state/users/users-list.state";
import {User} from "../../../../types/user";

const {Column} = Table;

export const UsersListPage = observer(() => {
  useEffect(() => {
    usersListState.getList();
  }, []);

  const renderActive = (value: boolean, record: User) => (
    <Switch
      checked={value}
      onChange={async v => {
        await usersListState.setActive(record.id, v);
        await usersListState.getList();
      }}
    />
  );

  const renderActions = (v: any, record: User) => (
    <Link to={`/users/${record.id}`}>
      <EditTwoTone
        style={{fontSize: '2em'}}
      />
    </Link>
  );

  return (
    <>
      <Col>
        <Row>
          <Col offset={22}>
            <Link to={'/users/add'}>
              <Button type="primary">
                Добавить
              </Button>
            </Link>
          </Col>
        </Row>
        <br/>
        <Table dataSource={usersListState.users}>
          <Column title="Имя" dataIndex="name" key="name"/>
          <Column title="Фамилия" dataIndex="surname" key="surname"/>
          <Column title="Отчество" dataIndex="patronymic" key="patronymic"/>
          <Column title="Роль" dataIndex="roleName" key="roleName" width={180}/>
          <Column title="Email" dataIndex="email" key="email"/>
          <Column title="Деактивировать/Активировать" dataIndex="isActive" width={100} key="isActive" render={renderActive}/>
          <Column title="Действия" render={renderActions}/>
        </Table>
      </Col>
    </>
  )
});

import {observer} from "mobx-react";
import {StoryForm} from "../../components/story-form";
import {useHistory, useParams} from "react-router-dom";
import {useEffect} from "react";
import {storyEditState} from "../../../../state/story/story-edit.state";
import {storyListState} from "../../../../state/story/story-list.state";

export const StoryEditPage = observer(() => {
  const history = useHistory();
  const {id} = useParams() as any;

  useEffect(() => {
    const stories = storyListState.stories;
    const coin = stories.find(e => e.id === id);
    storyEditState.reset();
    if (coin) {
      storyEditState.prefill(coin);
    } else {
      history.replace('/stories');
    }
  }, [history, id]);

  return (
    <>
      <StoryForm
        onSave={() => history.replace('/stories')}
      />
    </>
  )
});


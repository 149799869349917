import {makeAutoObservable, runInAction} from "mobx";
import {User} from "../../types/user";
import {ClientProvider} from "../../utils/apiClient/clientProvider";

export class UserEditState {
  private _id?: string;
  private _name: string = '';
  private _surname: string = '';
  private _patronymic: string = '';
  private _role: string = '';
  private _password: string = '';
  private _email: string = '';
  loading: boolean = false;
  saveResponse?: User;

  setLoading = (v: boolean) => this.loading = v;

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get role(): string {
      return this._role;
  }

  set role(value: string) {
      this._role = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get surname(): string {
    return this._surname;
  }

  set surname(value: string) {
    this._surname = value;
  }

  get patronymic(): string {
    return this._patronymic;
  }

  set patronymic(value: string) {
    this._patronymic = value;
  }

  constructor() {
    makeAutoObservable(this);
  }

  reset() {
    this.id = undefined;
    this.name = '';
    this.surname = '';
    this.patronymic = '';
    this.role = '';
    this.email = '';
    this.password = '';
    this.saveResponse = undefined;
  }

  prefill(data: Partial<User>) {
    this.id = data.id;
    this.name = data.name!;
    this.surname = data.surname!;
    this.patronymic = data.patronymic!;
    this.role = data.role!;
    this.email = data.email!;
    this.password = data.password!;
  }

  async trySave() {
    try {
      let res: any;
      this.setLoading(true);
      const data = {
        name: this._name,
        surname: this._surname,
        patronymic: this._patronymic,
        role: this._role,
        email: this._email,
        password: this._password
      };
      if (this.id) {
        res = await ClientProvider.authorized.put(`/user/${this.id}`, data);
      } else {
        res = await ClientProvider.authorized.post('/user', data);
      }
      if (!res.data.errors) {
        runInAction(() => {
          this.saveResponse = res.data;
        });
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}


export const userEditState: UserEditState = new UserEditState();

import {makeAutoObservable, runInAction} from "mobx";
import {ClientProvider} from "../../utils/apiClient/clientProvider";
import {Investment} from "../../types/investment";

class InvestmentsListState {
  investments: Investment[] = [];
  loading: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setLoading = (v: boolean) => this.loading = v;

  setInvestments(investments: Investment[]) {
    this.investments = investments;
  }

  async getList() {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.get('/investment');
      if (!res.data.errors) {
        runInAction(() => {
          this.investments = res.data.data;
        })
      } else {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }

  async delete(id: string) {
    try {
      this.setLoading(true);
      const res = await ClientProvider.authorized.delete(`/investment/${id}`);
      if (res.data.errors) {
        console.error(res);
      }
    } finally {
      this.setLoading(false);
    }
  }
}

export const investmentsListState: InvestmentsListState = new InvestmentsListState();

import {makeAutoObservable} from "mobx";
import {message} from "antd";
import {ClientProvider} from "../utils/apiClient/clientProvider";

class RestoreState {
    private _email: string = '';
    private _loading: boolean = false;
    private _code: string = '';
    private _password: string = '';
    private _repeatPassword: string = '';

    get loading(): boolean {
        return this._loading;
    }

    set loading(value: boolean) {
        this._loading = value;
    }

    get email(): string {
        return this._email;
    }

    set email(value: string) {
        this._email = value;
    }

    get code(): string {
        return this._code;
    }

    set code(value: string) {
        this._code = value;
    }

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        this._password = value;
    }

    get repeatPassword(): string {
        return this._repeatPassword;
    }

    set repeatPassword(value: string) {
        this._repeatPassword = value;
    }

    constructor() {
        makeAutoObservable(this);
    }

    async restoreReset(onSuccess: () => void) {
        try {
            this._loading = true;
            const res = await ClientProvider.authorized.post(`auth/change-password`, {
                login: this.email,
                password: this.password,
                code: this.code
            });
            if (res) {
                this._loading = false;
                if (!res.data.errors){
                    message.success("Пароль обновлен");
                    onSuccess();
                } else {
                    message.error("Произошла ошибка. Возможно код введен неверно, или истёк");
                }
            }
        } catch (e) {
            console.log(e);
            this._loading = false;
        }
    }
}

export const restoreState: RestoreState = new RestoreState();
